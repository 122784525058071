import {Message, Spin} from "view-design";

/**
 * HTTP请求-显示全局错误Toast
 *
 * @export
 * @param {*} response 发起请求的响应
 * @param {*} message 自定义错误信息（防止响应中没有错误信息返回）
 */
export function showGlobalErrorToast(response, message) {
  Message.destroy();
  if (response && !response.config.hideError) {
    Message.info(response.data.message || message);
  } else if (response && response.config.hideError) {

  } else if (!response && message) {
    Message.info(message)
  }
}

/**
 * HTTP请求-显示全局加载Loading
 *
 * @export
 * @param {*} config 发起请求的config
 * @param {string} [status='closed'] loading的状态
 */
export function showGlobalLoading(config, status = 'closed') {
  if (config && config.hideLoading) {
    Spin.hide();
  } else {
    if (!config || !config.hideLoading) {
      status === 'open' ? Spin.show({
        render: (h) => {
          return h('div', [
            h('Icon', {
              'class': 'demo-spin-icon-load',
              props: {
                type: 'ios-loading',
                size: 18
              }
            }),
            h('div', 'Loading')
          ])
        }
      }) : Spin.hide()
    }
  }
}
