import Vue from 'vue';
import Vuex from 'vuex';
import {Store} from 'vuex'
import userStore from './userStore';
import saveLocalPlugin from '../plugins/tools/saveLocalPlugin'

Vue.use(Vuex);
export default new Store({
  state: {
    type:1
  },
  mutations: {},
  modules: {
    userStore
  },
  plugins:[saveLocalPlugin]
});

