import moment from 'moment'
const floorTxtFT = {
  name: 'floorTxtFT',
  value: function(v) {
    if(!v) return '--'
    if(/PATENT_GOODS/.test(v)) {
      return '专利'
    } else if (/THIRD_PARTY/.test(v)) {
      return '店铺'
    } else if(/SERVICE_GOODS/.test(v)) {
      return '服务'
    } else {
      return '--'
    }
  }
}

const timeFT = {
  name: 'timeFT',
  value: function(v, ft) {
    if (!v) return '--'
    return moment(v).format(ft || 'YYYY-MM-DD HH:mm:ss')
  }
}

export default [floorTxtFT, timeFT]