<template>
  <div>
  <div class="common_header">
    <div class="header_text" style="display: flex;align-items: center;float: left;position: relative">
        <div class="platform" style="margin-right: 10px">
          <span>{{ siteTitle }}</span>
        </div>
        <div class="second_border" style="margin-left: 10px;margin-right: 10px"></div>
        <div class="zxsc">
                <span class="zxsc_span" style="font-family:'fzhj'!important;">
                    从此，创新走向市场！
                </span>
      </div>
    </div>
    <div class="tab_login_or_not">
      <span>欢迎您! {{ userName }}</span>
      <span
        v-if="enterpriseState === 'CERTIFIED'"
        style="color: #2290f7;padding-left: 0;margin-left: 5px"
        >已认证</span
      >
      <span
        v-if="enterpriseState === 'EXAMINING'"
        style="color: #37c5cf; font-weight: bold"
        >待审核</span
      >
      <span
        v-if="enterpriseState === 'UNCERTIFIED'"
        style="color: #ff6a00; font-weight: bold; cursor: pointer"
        @click="goRenzheng()"
        >未认证</span
      >
      <span @click="logOut">退出</span>
      <span @click="goIndex()">返回首页</span>
      <span @mouseleave="erweiFlag=false" @mouseover="erweiFlag=true" style="position: relative">微信公众号
                      <img v-show="erweiFlag" :src="erwei" alt="" style="height: 100px;width: 100px;position: absolute;top:40px;left: -10px">

      </span>
<!--      <span v-for="(item, index) in tabList" :key="index" @click="goIndex(item)" >{{-->
<!--        item-->
<!--      }}</span>-->
    </div>
  </div>


  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import baseUrl from "../plugins/http/baseUrl";
import { hostname } from "../config"
export default {
  name: "CommonHeader",
  mounted() {
      axios.get(`${baseUrl}/v1/site/one`).then(res => {
        if(res.status == 200){
          let data = res.data.result;
          document.title = data.title;
          this.siteUrl = data.logoImageUrl
          this.siteTitle = data.title
          this.erwei = data.qrCode
        }
      })
  },
  computed: {
    ...mapGetters({
      token: "userStore/token",
      userName: "userStore/userName",
      userId: "userStore/userId",
      enterpriseState: "userStore/enterpriseState",
    }),
  },
  data() {
    return {
      siteUrl:'',
      siteTitle:'',
      erweiFlag:false,
      erwei:"",
      footHide:false,
      weModal:false,
      isCer: true,
      tabList: [
        "平台首页",
        // "高校入驻",
        // "区域特色产业平台",
        "联系客服",
        "微信公众号",
        // "关于我们",
      ],
    };
  },
  methods: {
    ...mapMutations({
      setUserToken: "userStore/setUserToken",
      setUserName: "userStore/setUserName",
      setUserAccountType: "userStore/setUserAccountType",
      setUserMbrMemberId: "userStore/setUserMbrMemberId",
      setUserId: "userStore/setUserId",
      setUserEnterpriseState: "userStore/setUserEnterpriseState",
    }),
    logOut() {
      this.setUserToken("");
      this.setUserName("");
      this.setUserAccountType(-1);
      this.setUserMbrMemberId("");
      this.setUserId("");
      this.setUserEnterpriseState("");
      this.$cookies.set("token","",null,null,"." + hostname)
      this.$router.push({
        path: "/",
      });
    },
    goRenzheng() {
      this.$router.push({
        name: "enterprise-EnterpriseCertification",
      });
    },
    goIndex() {


      this.$router.push({
        path: "/",

      })
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1400px) {
  .header_text {
    display: none !important;
  }
}
.second_border {
  display: inline-block;
  width: 1px;
  height: 12px;
  background-color: rgb(127, 127, 127);
}

.wxQrcodePay {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 200px !important;
    height: 200px !important;
  }
}

.common_header {
  height: 41px;
  padding: 14px 0;
  line-height: 41px;
  background-color: rgb(245, 245, 245);
  width: 100%;
  min-width: 1300px;
}
.header_text {
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 4%;
}
.tab_login_or_not {
  padding-right: 4%;
  height: 13px;
  line-height: 13px;
  font-size: 13px;
  float: right;
  span {
    display: inline-block;
    padding-left: 15px;
    margin-left: 15px;
    height: 13px;
    border-left: 1px solid black;
    cursor: pointer;
  }
}
.tab_login_or_not span:nth-child(1),
.tab_login_or_not span:nth-child(2) {
  border: none;
}
</style>
