import axios from "axios";
import {storePersistKey} from "@/plugins/tools/Constant";
import {showGlobalErrorToast, showGlobalLoading} from './httpCompUtil'

axios.defaults.withCredentials = true
import httpErrorHandler from "./httpErrorHandler";
import httpResponseFilter from "./httpResponseFilter";
import {getLocalToken} from "@/plugins/tools/storage";
import router from "@/router";
import {allowSameRequest} from "../tools/util";


// 创建Axios实例
export const instance = axios.create({
    timeout: 1000 * 10,
    withCredentials: true,
    validateStatus: function (status) {
        return status >= 200 && status < 500;
    },
    headers: {
        "Content-Type": "application/json; charset=utf-8",

    },
});


// 请求队列
const queue = [];

// Ajax中断
const CancelToken = axios.CancelToken;

// 生成Token
const genToken = config => {
    // 拼接请求的url和方法，同样的url+方法即视为相同的请求
    return `${config.url}_${config.method}`;
};


// 中断重复的请求，并从队列中移除
const removeQueue = config => {
    for (let i = 0, size = queue.length; i < size; i++) {
        const task = queue[i];
        if (task && task.token === genToken(config)) {
            showGlobalLoading(config, "closed");
            task.cancel();
            queue.splice(i, 1);
        }
    }
};

// 请求拦截器
instance.interceptors.request.use(
    config => {

        // 中断之前的同名请求
        removeQueue(config);
        // 添加cancelToken

        // 添加cancelToken
        if (!allowSameRequest(config)) {
            config.cancelToken = new CancelToken(c => {
                queue.push({token: genToken(config), cancel: c});
            });
        }


        // 默认全局loading,取消请传入{hideLoading:true}
        showGlobalLoading(config, "open");
        if(config.ContentType){
            config.headers['Content-Type'] =  config.ContentType
        }
        // 请求头带上token
        // config.headers["token"] = window.localStorage.getItem('Authorization') || '';
        config.headers['token'] = getLocalToken();
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(response => {
    // 请求完成，移出队列
    removeQueue(response.config);
    if (response.data.code == '401') {
        localStorage.removeItem(storePersistKey)
        router.push({
            path: '/login',
        })
        return
    }

    // 业务错误过滤
    httpResponseFilter(response);
    // 返回请求数据
    return Promise.resolve(response.data);
}, httpErrorHandler);

/**
 * 封装后的ajax post方法
 *
 * @param {string} url 请求路径
 * @param {object} data 请求参数
 * @param {object} config 用户自定义设置
 * @returns
 */
export function post(
    url,
    data,
    config = {hideLoading: false, hideError: false}
) {
    return instance.post(url, data, config);
}

export function streamPost(
    url,
    data,
    config = {hideLoading: false, hideError: false}) {
    return instance.post(url, data, config, {'responseType': 'arraybuffer'});
}

/**
 * 封装后的ajax get方法
 *
 * @param {string} url 请求路径
 * @param {object} params 请求参数
 * @param {object} config 用户自定义设置
 * @returns
 */
export function get(
    url,
    params,
    config = {
        hideLoading: false,
        hideError: false
    }
) {
    return instance.get(url, {params, hideLoading: config.hideLoading, hideError: config.hideError});
}

/**
 * 封装后的ajax put方法
 *
 * @param {string} url 请求路径
 * @param {object} data 请求参数
 * @param {object} config 用户自定义设置
 * @returns
 */
export function put(
    url,
    data,
    config = {
        hideLoading: false
    }
) {
    return instance.put(url, data, config);
}

/**
 * 封装后的ajax delete方法
 *
 * @param {string} url 请求路径
 * @param {object} config 用户自定义设置
 * @returns
 */
export function del(
    url,
    params,
    config = {
        hideLoading: false,
        hideError: false
    }
) {
    return instance.delete(url, {data: params, hideLoading: config.hideLoading, hideError: config.hideError})
}

