import { hostname, projectHosts } from '../../config'
let baseUrl;
//`development` 开发版本 `production`  生产版本
if (process.env.NODE_ENV === `development`) {
  baseUrl = `http://${projectHosts.admin}.${hostname}/portal`;//服务器地址前缀
  // baseUrl = `http://192.168.0.44:8100`
} else {
  // baseUrl = `http://${projectHosts.admin}.${hostname}/portal`;//服务器地址前缀
  baseUrl = `//${projectHosts.data}.${hostname}/admin`;
}


export default baseUrl;


// export const qxtUrl = process.env.NODE_ENV === `development`? `http://${projectHosts.qxt}.${hostname}/portal`:`http://${projectHosts.qxt}.${hostname}/portal`
// export const patentUrl = process.env.NODE_ENV === `development`? `http://${projectHosts.service}.${hostname}/portal`:`http://${projectHosts.service}.${hostname}/portal`
// export const examUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.service}.${hostname}/portal`:`http://${projectHosts.service}.${hostname}/portal`
// export const policyUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.shenbao}.${hostname}/portal`:`http://${projectHosts.shenbao}.${hostname}/portal`
// export const imgUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.admin}.${hostname}/portal`:`http://${projectHosts.admin}.${hostname}/portal`
// export const certificationUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.qxt}.${hostname}/portal`:`http://${projectHosts.qxt}.${hostname}/portal`
// export const indexUrl = process.env.NODE_ENV === `development` ? `http://${projectHosts.cms}.${hostname}`: `http://${projectHosts.cms}.${hostname}`
// export const storebaseUrl = process.env.NODE_ENV === `development`?`https://${projectHosts.data}.${hostname}`:`https://${projectHosts.data}.${hostname}`
// export const resourceUrl = process.env.NODE_ENV === `development`?`https://${projectHosts.data}.${hostname}`:`https://${projectHosts.data}.${hostname}`
// export const nianfeiUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.service}.${hostname}/portal`:`http://${projectHosts.service}.${hostname}/portal`

export const qxtUrl = process.env.NODE_ENV === `development`? `/qxt`:`//${projectHosts.data}.${hostname}/qxt`
export const patentUrl = process.env.NODE_ENV === `development`? `http://${projectHosts.service}.${hostname}/portal`:`//${projectHosts.data}.${hostname}/service`
export const examUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.service}.${hostname}/portal`:`//${projectHosts.data}.${hostname}/service`
export const policyUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.shenbao}.${hostname}/portal`:`//${projectHosts.data}.${hostname}/shenbao`
export const imgUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.admin}.${hostname}/portal`:`//${projectHosts.data}.${hostname}/admin`
export const certificationUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.qxt}.${hostname}/portal`:`//${projectHosts.data}.${hostname}/qxt`
export const indexUrl = process.env.NODE_ENV === `development` ? `http://${projectHosts.cms}.${hostname}`: `//${projectHosts.data}.${hostname}/cms`
export const storebaseUrl = process.env.NODE_ENV === `development`?`http://192.168.0.26:4200`:`//${projectHosts.data}.${hostname}`
export const resourceUrl = process.env.NODE_ENV === `development`?`https://${projectHosts.data}.${hostname}`:`//${projectHosts.data}.${hostname}`
export const nianfeiUrl = process.env.NODE_ENV === `development`?`http://${projectHosts.service}.${hostname}/portal`:`//${projectHosts.data}.${hostname}/service`
export const zlhUrl = process.env.NODE_ENV === 'development1' ? 'http://www.cnuip.com/byzlh': '/byzlh' ;
// export const zlhUrl = process.env.NODE_ENV === 'development' ? ' http://api.zlhui.com/patent/searchPatent?appKey=e2add5fd9c1811eaaa24e0d55e0e1be5' : '/byzlh' ;

// 第三方万方地址
export const wfUrl = "http://webstads.sciinfo.cn"
