import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vueQr from 'vue-qr'
Vue.use(vueQr)
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Header from '@/components/CommonHeader.vue'
import '@/assets/css/style.css'
import '@/assets/css/main.css'
import '@/assets/css/iview-rewrite.css'
import '@/assets/iconfont/iconfont.css'
import  '@/assets/iconfonttoal/iconfont.css'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import VueCookies from 'vue-cookies'
import axios  from "axios";
//导入wordcloud  官方给的没用，可能不支持
import 'echarts-wordcloud/dist/echarts-wordcloud'
import ImageLoader from '@/components/common/imageLoader'
Vue.component('common-header',Header)
Vue.component('image-loader',ImageLoader)
Vue.use(ViewUI);
Vue.prototype.$cookies = VueCookies
Vue.config.productionTip = false;
import MetaInfo from 'vue-meta-info'
import baseUrl from "./plugins/http/baseUrl";
import ft from "./filter"
ft.forEach(item => {
  Vue.filter(item.name, item.value)
})
Vue.use(MetaInfo)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
