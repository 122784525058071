/**
 *用户相关store
 */
import {getUserDetailById} from "../plugins/api/loginApi";

const state = () => ({
  token: '',//用户登陆之后的token
  accountType: -1,//是否为主账号主账号1，副账号0
  mbrMemberId: '',//企业Id
  userName: '',//用户名
  userId: '',//用户ID
  enterpriseState: '',// UNCERTIFIED.未认证 CERTIFIED.已认证 EXAMINING.待审核,
  enterpriseId: ''
})

const getters = {
  token: state => state.token,
  accountType: state => state.accountType,
  mbrMemberId: state => state.mbrMemberId,
  userName: state => state.userName,
  userId: state => state.userId,
  enterpriseState: state => state.enterpriseState,
  enterpriseId: state => state.enterpriseId,
}
const mutations = {
  setUserToken(state, val) {
    state.token = val
  },
  setUserAccountType(state, val) {
    state.accountType = val
  },
  setUserMbrMemberId(state, val) {
    state.mbrMemberId = val
  },
  setUserName(state, val) {
    state.userName = val
  },
  setUserId(state, val) {
    state.userId = val
  },
  setUserEnterpriseState(state, val) {
    state.enterpriseState = val
  },
  setUserEnterpriseId(state, val){
    state.enterpriseId = val;
  }

}

const actions = {

  async getUserDetailInfo(options, payload) {
    if (!options.state.userId) {
      return
    }
    const params = {
      id: options.state.userId
    }
    const json = await getUserDetailById(params);
    if (json.code !== 0) {
      return;
    }
    if (json.result.username !== options.state.userName) {
      options.commit('setUserName', json.result.username);
    }
    if (json.result.accountType !== options.state.accountType) {
      options.commit('setUserAccountType', json.result.accountType);
    }
    if (json.result.mbrMemberId !== options.state.mbrMemberId) {
      options.commit('setUserMbrMemberId', json.result.mbrMemberId);
    }
    if (json.result.member.enterpriseState !== options.state.enterpriseState) {
      options.commit('setUserEnterpriseState', json.result.member.enterpriseState)
    }
    if (json.result.member.enterpriseId !== options.state.enterpriseId) {
      options.commit('setUserEnterpriseId', json.result.member.enterpriseId)
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
