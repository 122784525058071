
import { showGlobalErrorToast } from './httpCompUtil'


export default (response) => {

  // 业务状态码-判断
  switch (response.data && response.data.rescode) {
    case 401: // 401: 未登录
      showGlobalErrorToast(response,'未登录');
      break;
    case 403: // 403 token过期
      showGlobalErrorToast(response, '登录过期，请重新登录');
      break;
    case 404: // 404请求不存在
      showGlobalErrorToast(response, '请求的资源不存在');
      break;
    case 500:
    case 501:
    case 502:
    case 503:
    case 504:
      showGlobalErrorToast(response, '服务器错误');
      break;
  }
}
