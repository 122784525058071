<template>
  <div :style="[basicStyle, addStyle]" class="dp-flex flex-center flex-align-center img-con">
    <img v-if="rawSrc" :src="rawSrc" :style="[imgStyle]" />
  </div>
</template>

<script>
export default {
  name: "imageLoader",
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: 'contain'
    },
    errPic: {
      type: String,
      default: ''
    },
    backPic: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    picStyle: {
      type: Object,
      default: () => {}
    },
    align: {
      type: String,
      default: ""
    },
    customRatio: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ratio: 1,
      rawSrc: '',
      isErr: 0,
      basicStyle: {
        width: this.width + 'px',
        height: this.height + 'px'
      },
      addStyle:{
      },
      imgStyle: {
      }
    };
  },
  created() {
    let w = this.width, h = this.height, mod = this.mode;
    let ratio = w / h;
    let imgEle = new Image();
    imgEle.src = this.src;
    imgEle.onload = () => {
      this.rawSrc = this.src;
      if(this.backPic) {
        this.addStyle = {
          backgroundImage: `url("${this.backPic}")`,
          backgroundSize: '100% 100%'
        }
      }
      let originW = imgEle.width, originH = imgEle.height;
       if(mod === 'contain') {
          if(originW / originH > ratio) {
            this.imgStyle = Object.assign({
              width: "100%"
            }, this.picStyle)
          } else {
            this.imgStyle = Object.assign({
              height: "100%"
            }, this.picStyle)
          }
        } else if (mod === 'cover') {
          if(originW / originH > ratio) {
            this.imgStyle = Object.assign({
              height: "100%",
              width: this.customRatio ? this.customRatio +'%' : "auto"
            }, this.picStyle)
            if (this.align) {
              this.addStyle['justifyContent'] = `flex-${this.align}`
            }
          } else {
            this.imgStyle = Object.assign({
              width: "100%",
              height: this.customRatio ? this.customRatio +'%' : "auto"
            }, this.picStyle)
            if (this.align) {
              this.addStyle['alignItems'] = `flex-${this.align}`
            }
          }
        }  else {
          this.imgStyle = Object.assign({
            width: "100%",
            height: "100%"
          }, this.picStyle)
        }
    }
    imgEle.onerror = () => {
      if(this.errPic) {
        this.addStyle = {
          backgroundImage: `url("${this.errPic}")`,
          backgroundSize: '100% 100%'
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.img-con{
  overflow: hidden;
}
</style>
