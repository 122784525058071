import Vue from "vue";
import VueRouter from "vue-router";
import {storePersistKey} from "@/plugins/tools/Constant";
import {getLocalToken} from "@/plugins/tools/storage";
import store from '@/store';
import VueCookies from 'vue-cookies'
import axios from "axios";
import baseUrl from "../plugins/http/baseUrl";

Vue.use(VueRouter);
Vue.prototype.$cookies = VueCookies

const routes = [
    {
        path: "/",
        component: () => import('@/pages/home/index'),
        name: "list.vue",
        redirect: '/home/index'
    },
    // {
    //     path: "/",
    //     component: () => import('@/pages/home/index'),
    //     name: "index",
    //     redirect:'/home/index'
    // },

    {
        path: "/home/good",
        component: () => import('@/pages/good'),
        name: "good"
    },
    {
        path: "/examReport",
        component: () => import('@/pages/ExamReport'),
        name: 'ExamReport'
    },
    {
        path: "/clearCookie",
        component: () => import('@/pages/clearCookie'),
        name: 'clearCookie'
    },
    {
        path: "/login",
        component: () => import('@/pages/login'),
        name: "login"
    }, {
        path: "/enterprise/EnterpriseCertification",
        component: () => import('@/pages/enterprise/EnterpriseCertification'),
        name: "enterprise-EnterpriseCertification"
    }, {
        path: "/enterprise/:pgId",
        component: () => import('@/pages/enterprise/index'),
        name: "enterprise"
    }, {
        path: "/report-result",
        component: () => import('@/components/science/EnterprisePhysicalExamination/ReportResult'),
        name: "enterprise-report-result"
    }, {
        path: "/home/:pageId",
        component: () => import('@/pages/home/index'),
        name: "home"
    },
    {
        path: "/qxt",
        component: () => import('@/pages/qxt/index'),
        name: "qxt"
    },
    {
        path: "/qxt_result",
        component: () => import('@/pages/qxt/result'),
        name: "qxt_result"
    },
    {
        path: "/matchList",
        component: () => import('@/pages/qxt/matchList'),
        name: "matchList"
    },
];

const router = new VueRouter({
    routes,
    mode: 'hash'
});


const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}


router.afterEach(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})

router.beforeEach((to, from, next) => {
        if (to.path === '/login' || to.path.indexOf("/home") > -1 || to.path === '/examReport'|| to.path === '/matchList') {
            getLocalToken()
            next();
        } else {
            let token = ""
            let data = document.cookie.split(";")
            for (let i = 0; i < data.length; i++) {
                let res = data[i].split("=")
                if (res[0].trim() == "token") {
                    token = res[1]
                    break;
                }
            }
            if (getLocalToken() === null || getLocalToken() == "" || token == "") {
                next('/login');
            } else {
                next();
            }
        }
    }
)
export default router;
